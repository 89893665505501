import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { validate } from "react-email-validator";
import axios from "axios";
import BlueEmailInputField from "../BlueEmailInputField/BlueEmailInputField";
import BlueInputField from "../BlueInputField/BlueInputField";
import WhitePillSubmitButton from "../WhitePillSubmitButton/WhitePillSubmitButton";
import WhiteCheckbox from "../WhiteCheckBox/WhiteCheckBox";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import tcConsumersPDF from "../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";
import rules from "../../assets/docs/ActiereglementConsumentHétLiptonTerrasvan2024.pdf";

const ContestQuestionsNetherlands = (props) => {
  const {
    setEmailPopupIsVisible,
    formIsSubmitted,
    setFormIsSubmitted,
    setIgnoredOrFinishedCompetition,
  } = props;
  const intl = useIntl();
  const formRef = useRef();
  const [questionOneAnswer, setQuestionOneAnswer] = useState("");
  const [marketingOptIn, setMarketingOptIn] = useState(false);
  const [unileverMarketingOptIn, setUnileverMarketingOptIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState();
  const [ageIsConfirmed, setAgeIsConfirmed] = useState(false);
  const [emailAlreadyJoined, setEmailAlreadyJoined] = useState(false);
  // The object to be sent for storing in the DB
  const answers = {
    question_one: questionOneAnswer,
    first_name: firstName,
    email: email,
    session_token: sessionStorage.getItem("access_token"),
    marketing_opt_in: marketingOptIn,
    unilever_marketing_opt_in: unileverMarketingOptIn,
  };

  // Stores whether user filled everything in
  const [formIsFilled, setFormIsFilled] = useState(false);

  // Checks if user filled in every field and if provided email address is a valid format
  const checkIfFormIsFilled = () => {
    if (questionOneAnswer && email && validate(email)) {
      return setFormIsFilled(true);
    }
    return setFormIsFilled(false);
  };

  useEffect(() => {
    checkIfFormIsFilled();
  }, [questionOneAnswer, email]);

  const registerParticipation = async () => {
    // Notify if and which fields are missing
    if (!formIsFilled) {
      window.dataLayer.push({
        event: "app-flow",
        action: "form-submit-attempt",
      });
      formRef.current.checkValidity();
      formRef.current.reportValidity();
      return;
    }
    if (ageIsConfirmed === false) {
      window.dataLayer.push({
        event: "app-flow",
        action: "form-submit-attempt",
      });
      return alert(intl.formatMessage({ id: "confirm_age_alert" }));
    }

    // Send POST-request to API to save answers and user's email
    try {
      window.dataLayer.push({
        event: "app-flow",
        action: "form-submit-success",
      });

      window.dataLayer.push({
        event: "app-flow",
        action: "form-submit-attempt",
      });

      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/api/raffle-entry",
        data: answers,
      });

      // Print log to console when submission has failed
      if (response.status !== 200) {
        console.log(`Something went wrong: HTTP Status ${response.status}`);
      }
      if (response.status === 201) {
        setEmailAlreadyJoined(true);
        return;
      }

      // Used to prevent competition pop-up from showing again in Thanks for Voting-page
      localStorage.setItem("did_participate", true);
      return setFormIsSubmitted(true); //bad state
    } catch (e) {
      // console.log(`Something went wrong: ${e}`);
    }
    return answers;
  };

  return (
    <>
      <div className="contest-questions-container">
        <h2>
          <FormattedMessage id="sign_up_to_win_enter" />
        </h2>

        <form ref={formRef}>
          <div className="contest-form-questions-container">
            <span className="contest-question-text">
              <h4>
                <FormattedMessage id="competition_sub_text" />
              </h4>
            </span>
          </div>

          <div className="enter-email-form-container">
            <div
              style={
                formIsSubmitted ? { display: "none" } : { display: "block" }
              }
            >
              <BlueInputField
                inputPlaceholder={intl.formatMessage({
                  id: "raffle_input_placeholder",
                })}
                setValue={setQuestionOneAnswer}
                required={true}
                formIsSubmitted={formIsSubmitted}
                onInvalidMessage={intl.formatMessage({
                  id: "text_input_invalid",
                })}
              />
              <BlueInputField
                inputPlaceholder={intl.formatMessage({
                  id: "name_input_placeholder",
                })}
                setValue={setFirstName}
                required={true}
                formIsSubmitted={formIsSubmitted}
                onInvalidMessage={intl.formatMessage({
                  id: "text_input_invalid",
                })}
              />
              <BlueEmailInputField
                inputPlaceholder={intl.formatMessage({
                  id: "enter_email_input_placeholder",
                })}
                setEmail={setEmail}
                formIsSubmitted={formIsSubmitted}
                onInvalidMessage={intl.formatMessage({
                  id: "email_input_invalid",
                })}
              />
              {emailAlreadyJoined && (
                <p className="joined-notification">
                  {intl.formatMessage({ id: "already_joined_email" })}
                </p>
              )}
              <div className="checkbox-questions">
                <div className="check-box-question-container">
                  <WhiteCheckbox
                    required={true}
                    checkboxId={"confirmAgeCheckbox"}
                    value={ageIsConfirmed}
                    onClick={() => {
                      setAgeIsConfirmed(
                        (prevAgeConfirmed) => !prevAgeConfirmed
                      );
                    }}
                  />
                  <span className="checkbox-question-text">
                    <p>
                      <FormattedMessage id="age_checkbox_1" />
                      <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                        <FormattedMessage id="age_checkbox_tc_href_terms" />
                      </a>
                      <FormattedMessage id="age_checkbox_2" />
                      <a href={rules} target="_blank" rel="noreferrer">
                        <FormattedMessage id="age_checkbox_tc_href_rules" />
                      </a>
                      *
                    </p>
                  </span>
                </div>
                <div className="check-box-question-container">
                  <WhiteCheckbox
                    required={false}
                    checkboxId={"marketingOptInCheckbox"}
                    value={marketingOptIn}
                    onClick={() => {
                      setMarketingOptIn(
                        (prevMarketingOptIn) => !prevMarketingOptIn
                      );
                      setUnileverMarketingOptIn(
                        (prevUnileverMarketingOptIn) =>
                          !prevUnileverMarketingOptIn
                      );
                    }}
                  />
                  <span className="checkbox-question-text">
                    <p>
                      <FormattedMessage id="unilever_marketing_opt_in_text_1" />{" "}
                      <a
                        href="https://www.unilever.nl/brands/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="unilever_marketing_opt_in_text_anchor" />
                      </a>
                      <FormattedMessage id="unilever_marketing_opt_in_text_2" />
                    </p>
                  </span>
                </div>
              </div>
            </div>

            {formIsSubmitted ? (
              setEmailPopupIsVisible(false)
            ) : (
              <div className="white-submit-button">
                <WhitePillSubmitButton
                  buttonText={intl.formatMessage({ id: "submit_button" })}
                  onClick={(e) => {
                    registerParticipation();
                    e.preventDefault();
                    setIgnoredOrFinishedCompetition(true);
                  }}
                  style={{
                    width: "80%",
                  }}
                />
              </div>
            )}

            <span className="legal-line-container-popup">
              <p>
                <FormattedMessage id="legal_line" />
                <a href={rules} target="_blank" rel="noreferrer">
                  <FormattedMessage id="legal_href" />
                </a>
                <FormattedMessage id="legal_line3" />
                <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                  <FormattedMessage id="legal_href2" />
                </a>

                <FormattedMessage id="legal_line2" />
              </p>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContestQuestionsNetherlands;
