import React, { useState, useEffect } from "react";
import "./styles.scss";
import WhitePillButton from "../WhitePillButton/WhitePillButton";
import Map from "../GoogleMap/GoogleMap";
import axios from "axios";
import getCurrentDate from "../../utils/date";
import { FormattedMessage } from "react-intl";
import { Wrapper } from "@googlemaps/react-wrapper";
import { motion } from "framer-motion";
import SliderIconBar from "../SliderIconBar/SliderIconBar";

const MapView = (props) => {
  const {
    selectedVenue,
    navigate,
    retrieveAccessToken,
    userAgentData,
    venues,
    setSelectedVenue,
    setShowMap,
    setShowNearestTerrace,
    setVenues,
    hasLocationPermission,
    setHasLocationPermission,
    initialPermissionCheck,
    setInitialPermissionCheck,
    trafficOrigin,
    setNotification,
    setHasVoted,
    setSearchParams,
    searchParams,
  } = props;

  const [emailPopupIsVisible, setEmailPopupIsVisible] = useState(false);
  const [userLocation, setUserLocation] = useState();
  const [publicIp, setPublicIp] = useState("000000000");

  let dateLastVoted = localStorage.getItem("date_last_voted");
  let currentDate = getCurrentDate();

  const voteData = {
    venue_name: selectedVenue.venue_name,
    secret_ip: publicIp,
    session_token: sessionStorage.getItem("access_token"),
    os_version: userAgentData.os.name,
    browser: userAgentData.browser.name,
    browser_version: userAgentData.browser.version,
    latitude: selectedVenue.latitude,
    longitude: selectedVenue.longitude,
    venue_id: selectedVenue.id,
    province: selectedVenue.province,
    traffic_origin: trafficOrigin || "na",
  };

  const processVote = async () => {
    // Check if user has already voted based on date
    if (dateLastVoted === currentDate) {
      setHasVoted(true);
      window.dataLayer.push({
        event: "app-flow",
        action: "vote-attempt-within-24hrs",
      });
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + `/api/votes`,
        data: voteData,
      });

      // Retrieve new access token if current is expired (lifetime is 10 mins.)
      if (response.status === 401) {
        retrieveAccessToken();
        return processVote();
      }
      if (response.status === 200) {
        localStorage.setItem("date_last_voted", currentDate);
        window.dataLayer.push({
          event: "app-flow",
          action: "user-has-voted",
        });
        return navigate("/bedankt-voor-het-stemmen");
      } else {
        // return console.log(response.status);
      }
    } catch (e) {
      if (e.toString() === "Error: Request failed with status code 422") {
        if (e.toString() === "Error: Request failed with status code 422") {
          if (localStorage.getItem("locale") === "nl-NL") {
            setNotification(
              "Oeps! Er lijkt iets fout te gaan, refresh de pagina en probeer het snel nog eens! (422)"
            );
          } else {
            setNotification(
              "Oops! Something went wrong, click on the logo to refresh the page and try it again. (422)"
            );
          }
        }
      }
      console.log(`Something went wrong: ${e}`);
    }
  };

  const retrieveNearestTerrace = (position) => {
    console.log(position);
    try {
      axios({
        method: "get",
        url:
          process.env.REACT_APP_BACKEND_URL +
          `/api/venues/search-venue-by-coords?longitude=${position.longitude}&latitude=${position.latitude}`,
      }).then((response) => {
        setVenues(response.data);
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Callback function when position retrievement was succesful
  const positionRetrievalSucceeded = (pos) => {
    let position = pos.coords;
    setShowNearestTerrace(true);
    setUserLocation(pos.coords);
    setHasLocationPermission(true);
    setShowMap(true);
    setInitialPermissionCheck(false);

    if (
      searchParams.get("terras") === "" &&
      searchParams.get("search") === ""
    ) {
      retrieveNearestTerrace(position);
    }
  };

  // Callback function when position retrievement failed/user rejected
  const positionRetrievalFailed = () => {
    console.log(
      "There was an error retrieving the user's position. Probably didn't give permission."
    );
    setHasLocationPermission(false);

    if (initialPermissionCheck) {
      setInitialPermissionCheck(false);
      setShowMap(false);
    }
  };

  // Retrieve user's public IP
  useEffect(() => {
    axios
      .get("https://api64.ipify.org?format=json")
      .then((result) => {
        setPublicIp(result.data.ip);
      })
      .catch((e) => {
        console.log(
          `Something went wrong while retrieving the user's public IP: ${e}`
        );
      });
  }, []);

  // Email popup animate variants
  const emailPopupVariants = {
    show: {
      bottom: "0%",
      display: "block",
    },
    hide: {
      bottom: "-100%",
      display: "none",
    },
  };

  // Search bar animation settings
  const popupAnimation = { type: "spring", damping: 22, stiffness: 150 };

  return (
    <>
      <Wrapper
        apiKey={"AIzaSyAeoYx_teWQSUg68xoYVoYmUBJNtIrZBh8"}
        className="map"
      >
        <Map
          venues={venues}
          setSelectedVenue={setSelectedVenue}
          selectedVenue={selectedVenue}
          setVenueSelectionIsVisible={setEmailPopupIsVisible}
          userLocation={userLocation}
          positionSucceedCallback={positionRetrievalSucceeded}
          positionFailedCallback={positionRetrievalFailed}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          hasLocationPermission={hasLocationPermission}
        />
      </Wrapper>
      <motion.div
        className="contest-popup-container-map"
        animate={emailPopupIsVisible ? "show" : "hide"}
        variants={emailPopupVariants}
        transition={popupAnimation}
      >
        <>
          <SliderIconBar />
          <div className="confirm-vote-popup">
            <p>
              <b>
                <FormattedMessage id="your_favorite_terrace" />
              </b>
            </p>
            <h2>{selectedVenue.venue_name}</h2>
            <WhitePillButton
              buttonText={<FormattedMessage id="vote_button" />}
              style={{ width: "90%", height: "45px" }}
              onClick={(e) => {
                processVote();
              }}
            />
            <p className="bottom-message">
              <FormattedMessage id="not_the_one" />
              <br />
              <FormattedMessage id="please_use_searchbar" />
            </p>
          </div>
        </>
      </motion.div>
    </>
  );
};

export default MapView;
