import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Accordion = (props) => {
  
  const {isActive, onClick} = props;

  return (
    <>
        <li onClick={onClick} >
            <div className = {isActive ? "menu-item active": "menu-item"}>
                <span>
                <h4 className="menu-item-title">
                    {props.accordion_title}
                </h4>
                </span>
                <span className="menu-item-icon">
                    {isActive ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </span>
            </div>
        </li>
      {isActive && <div className="menu-sub-items">{props.accordion_content}</div>}
    </>
  );
};

export default Accordion;