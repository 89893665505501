import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ExitCrossButton = (props) => {
  const { onClick } = props;

  return (
    <div onClick={onClick} className="nav-icon-wrapper" id="exit-button">
      <FontAwesomeIcon icon={faTimes} className="nav-icon" />
    </div>
  );
};

export default ExitCrossButton;
