import React from "react";
import "./styles.scss";
import { Textfit } from "react-textfit";

const WhitePillButton = (props) => {
  const { buttonText, onClick, style, justifyEnd } = props;

  return (
    <>
      <div className={'white-pill-button-container ' + justifyEnd}>
        <button style={style} onClick={onClick} className="white-pill-button">
          <Textfit min={18} max={22} mode="single">
            <p>
              <strong>{buttonText}</strong>
            </p>
          </Textfit>
        </button>
      </div>
    </>
  );
};

export default WhitePillButton;
