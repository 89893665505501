import React from "react";
import "./styles.scss";

const MenuLanguageButton = (props) => {
  const { setLocale, languageCode, languageText } = props;

  let current;

  if (localStorage.getItem('locale') === null){
    current = "nl-NL"
  } else {
    current = localStorage.getItem('locale');
  }

  return (
    <div 
      className={`menu-language-button ${current === languageCode ? "active" : null}`}
      onClick={() => setLocale(languageCode)}>
      <div className="language-button-text">
        <h2>
          <strong>{languageText}</strong>
        </h2>
      </div>
    </div>
  );
};

export default MenuLanguageButton;
