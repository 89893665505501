import React, { useEffect } from "react";
import "./styles.scss";
import SliderIconBar from "../SliderIconBar/SliderIconBar";
import WhitePillButton from "../WhitePillButton/WhitePillButton";
import axios from "axios";
import getCurrentDate from "../../utils/date";
import { FormattedMessage } from "react-intl";

const TerraceLink = (props) => {
  const {
    navigate,
    retrieveAccessToken,
    userAgentData,
    trafficOrigin,
    selectedVenue,
    setNotification,
    setHasVoted,
  } = props;

  const publicIp = "000000000";
  let dateLastVoted = localStorage.getItem("date_last_voted");
  let currentDate = getCurrentDate();

  const voteData = {
    venue_name: selectedVenue.venue_name,
    secret_ip: publicIp,
    session_token: sessionStorage.getItem("access_token"),
    os_version: userAgentData.os.name,
    browser: userAgentData.browser.name,
    browser_version: userAgentData.browser.version,
    latitude: selectedVenue.latitude,
    longitude: selectedVenue.longitude,
    venue_id: selectedVenue.id,
    province: selectedVenue.province,
    traffic_origin: trafficOrigin || "na",
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "app-flow",
      action: "participated-through-deep-link",
    });
  }, []);

  const processVote = async () => {
    if (dateLastVoted === currentDate) {
      setHasVoted(true);
      window.dataLayer.push({
        event: "app-flow",
        action: "vote-attempt-within-24hrs-through-deep-link",
      });
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + `/api/votes`,
        data: voteData,
      });

      console.log(response);
      if (response.status === 401) {
        retrieveAccessToken();
        return processVote();
      }
      if (response.status === 200) {
        localStorage.setItem("date_last_voted", currentDate);
        window.dataLayer.push({
          event: "app-flow",
          action: "user-voted-through-deep-link",
        });
        return navigate("/bedankt-voor-het-stemmen");
      } else {
        return console.log(response.status);
      }
    } catch (e) {
      if (e.response) {
        console.error("Response Data:", e.response.data);
        console.error("Response Headers:", e.response.headers);
      } else if (e.request) {
        console.error("Request Data:", e.request);
      } else {
        console.error("Error Message:", e.message);
      }
      if (e.response && e.response.status === 422) {
        if (localStorage.getItem("locale") === "nl-NL") {
          setNotification(
            "Oeps! Er lijkt iets fout te gaan, refresh de pagina en probeer het snel nog eens! (422)"
          );
        } else {
          setNotification(
            "Oops! Something went wrong, click on the logo to refresh the page and try it again. (422)"
          );
        }
      }
    }
  };

  return (
    <>
      <div className="terrace-page-container"></div>
      <div className="terrace-popup-container-map">
        <SliderIconBar />
        <p>
          <b>
            <FormattedMessage id="your_favorite_terrace" />
          </b>
        </p>
        <h2>{selectedVenue.venue_name}</h2>

        <div className="popup-bottom">
          <WhitePillButton
            buttonText={<FormattedMessage id="vote_button" />}
            style={{ width: "100%", height: "50px", fontSize: "0.8em" }}
            onClick={(e) => {
              processVote();
            }}
          />
          <p>
            <FormattedMessage id="not_the_one" />
            <br />
            <FormattedMessage id="please_use_searchbar" />
          </p>
        </div>
      </div>
    </>
  );
};

export default TerraceLink;
