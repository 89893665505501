import React from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import tcConsumersPDF from "../../../../assets/docs/ActievoorwaardenBusinessHétLiptonTerrasvan2024.pdf";
import tcBusinessPDF from "../../../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";

const TermsConditionsSelector = () => {
  return (
    <>
      <div className="terms-conditions-selector-container">
        <ul className="terms-conditions-list">
          <li className="terms-conditions-item">
            <span>
              <h4 className="menu-item-title">
                <a href={tcBusinessPDF} target="_blank" rel="noreferrer">
                  <FormattedMessage id="menu_terms_conditions_business" />
                </a>
              </h4>
            </span>
            <span className="menu-item-icon">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          </li>

          <li className="terms-conditions-item" id="last-item">
            <span>
              <h4 className="menu-item-title">
                <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                  <FormattedMessage id="menu_terms_conditions_consumers" />
                </a>
              </h4>
            </span>
            <span className="menu-item-icon">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TermsConditionsSelector;
