import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const BackButton = (props) => {
  const { onClick, style } = props;

  return (
    <div style={style} onClick={onClick} className="nav-icon-wrapper">
      <FontAwesomeIcon icon={faArrowLeft} className="nav-icon" />
    </div>
  );
};

export default BackButton;
