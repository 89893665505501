import React from "react";
import "./styles.scss";
import MenuLanguageButton from "./MenuLanguageButton";
import { FormattedMessage } from "react-intl";

const MenuLanguageSelector = (props) => {
  const { handleLanguageSelection } = props;

  const processLanguageSelection = (languageCode) => {
    handleLanguageSelection(languageCode);
  };

  const languageOptions = [
    {
      language: "NL",
      languageCode: "nl-NL",
    },
    {
      language: "EN",
      languageCode: "en-US",
    },
  ];

  return (
    <>
      <div
        className="menu-language-selector-container"
        style={{ display: `${props.displayStyle}` }}
      >
        <p>
          <FormattedMessage id="select_language" />
        </p>
        <div className="menu-language-selector-buttons-container">
          {languageOptions.map((option, index) => {
            return (
              <MenuLanguageButton
                languageText={option.language}
                languageCode={option.languageCode}
                setLocale={processLanguageSelection}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuLanguageSelector;
