import React from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import VenueSearchField from "../../components/VenueSearchField/VenueSearchField";
import mapIcon from "../../assets/img/icons/Icon_map.svg";

const InitialSearchMenu = (props) => {
  const {
    isHomepage,
    initialSearch,
    retrieveAccessToken,
    notification,
    setNotification,
    setVenues,
    setSearchParams,
    searchVenueByClick,
    searchBarPlaceholder,
  } = props;

  const mapViewSearch = () => {
    initialSearch(false);
    setSearchParams({ view: "map" });

    if (sessionStorage.getItem("user-started-search") === null) {
      window.dataLayer.push({
        event: "app-flow",
        action: "user-searched-for-venue",
      });
      sessionStorage.setItem("user-started-search", "true");
    }
  };

  return (
    <>
      <div className="initial-search-field body">
        <p>
          <FormattedMessage id="please_use_searchbar_no_venue_1" />
        </p>
        <h2>
          <FormattedMessage id="please_use_searchbar_no_venue_2" />
        </h2>
        <div className="search-field">
          <VenueSearchField
            withButton
            isHomepage={isHomepage}
            retrieveAccessToken={retrieveAccessToken}
            notification={notification}
            setNotification={setNotification}
            setVenues={setVenues}
            searchVenueByClick={searchVenueByClick}
            searchBarPlaceholder={searchBarPlaceholder}
          />
        </div>
      </div>

      <div className="search-map-section">
        <p>
          <FormattedMessage id="find_it_on_the_map" />
        </p>

        <img
          alt=""
          src={mapIcon}
          onClick={() => mapViewSearch()}
          className="map-icon"
        />
      </div>
    </>
  );
};

export default InitialSearchMenu;
