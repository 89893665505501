import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

const PrizesPage = (props) => {
  const { setActiveMenuHeading } = props;

  useEffect(() => {
    setActiveMenuHeading("menu_prizes");
  });

  return (
    <>
      <div className="prizes-page-content">
          <h2>
            <FormattedMessage id="participants_vote_heading"/>
          </h2>
          <p>
            <FormattedMessage id="participants_vote_content_1"/>
            <a href="https://www.hetliptonterras.nl">
              <FormattedMessage id="participants_vote_content_link"/>
            </a>
            <FormattedMessage id="participants_vote_content_2"/>
          </p>
          <h2>
            <FormattedMessage id="participants_business_heading"/>
          </h2>
        <p>
          <FormattedMessage id="participants_business_content"/>
          <br></br>
          <br></br>
          <FormattedMessage id="participants_business_prizes_1"/>
          <br></br>
          <br></br>
          <FormattedMessage id="participants_business_prizes_2"/>
          <br></br>
          <br></br>
          <FormattedMessage id="participants_business_prizes_3"/>
          <br></br>
          <br></br>
          <FormattedMessage id="participants_business_prizes_4"/>
        </p>
      </div>
    </>
  );
};

export default PrizesPage;
