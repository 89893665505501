import React, { useState, useEffect } from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";

//Component imports
import ScoreboardResults from "./Results/ScoreboardResults";
import WinnersResults from "./Results/WinnersResults";

const Scoreboard = (props) => {
  const { setActiveMenuHeading } = props;

  useEffect(() => {
    setActiveMenuHeading("menu_scoreboard");
  });

  const handleBoard = (e) => {
    if (e.target.id === "winners") {
      isSelected(true);
    }
    if (e.target.id === "scoreboard") {
      isSelected(false);
    }
  };
  const [selected, isSelected] = useState(false);

  return (
    <>
      <div className="scoreboard-container">
        <h2 className="scoreboard-header">Scoreboard</h2>
        <div className="scoreboard-content-handler">
          <button
            onClick={handleBoard}
            id="scoreboard"
            className={
              selected ? "scoreboard-choice" : "scoreboard-choice-selected"
            }
          >
            <FormattedMessage id="scoreboard_results_button" />
          </button>
          <button
            onClick={handleBoard}
            id="winners"
            className={
              selected ? "scoreboard-choice-selected" : "scoreboard-choice"
            }
          >
            <FormattedMessage id="scoreboard_winners_button" />
          </button>
        </div>

        {selected ? <WinnersResults /> : <ScoreboardResults />}
      </div>
    </>
  );
};

export default Scoreboard;
