import React from "react";
import "./styles.scss";

const WhitePillSubmitButton = (props) => {
  const { buttonText, onClick } = props;

  return (
    <>
      <div className="white-pill-button-container">
        <button onClick={onClick} className="white-pill-button" type="submit">
          <h2>
            <strong>{buttonText}</strong>
          </h2>
        </button>
      </div>
    </>
  );
};

export default WhitePillSubmitButton;
