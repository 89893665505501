import React from "react";
import "./styles.scss";
import WhitePillButton from "../WhitePillButton/WhitePillButton";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import tcConsumersPDF from "../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";

const ContestMessage = (props) => {
  const { setEmailPopupIsVisible, setParticipateInContest } = props;
  const intl = useIntl();
  const Locale = intl.locale;

  const showContestQuestion = () => {
    setEmailPopupIsVisible(false);
    setTimeout(() => setEmailPopupIsVisible(true), 300);
    setTimeout(() => setParticipateInContest(true), 300);
    window.dataLayer.push({
      event: "app-flow",
      action: "user-accepted-contest-question",
    });
  };

  return (
    <>
      <div className="enter-email-popup">
        <span className="enter-email-popup-heading">
          <h2
            className={Locale === "nl-NL" ? "heading-large" : "heading-medium"}
          >
            <FormattedMessage id="contest_message_heading" />
          </h2>
          <p>
            <FormattedMessage id="contest_message_text" />
          </p>
        </span>

        <div className="lipton-club-button">
          <WhitePillButton
            buttonText={intl.formatMessage({ id: "accept_contest_button" })}
            onClick={() => showContestQuestion()}
          />
        </div>

        <p className="view-terms-and-conditions">
          <FormattedMessage id="view_terms_and_conditions" />
          <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
            <FormattedMessage id="view_terms_link" />
          </a>
        </p>
      </div>
    </>
  );
};

export default ContestMessage;
