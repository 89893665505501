import React, { useState } from "react";
import "./styles.scss";
import ExitCrossButton from "../ExitCrossButton/ExitCrossButton";
import BackButton from "../BackButton/BackButton";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";

import MenuItems from "./MenuItems/MenuItems";
import Scoreboard from "../Scoreboard/Scoreboard";
import Prizes from "./MenuItems/Prizes/PrizesPage";
import Winners from "./MenuItems/Winners/WinnersPage";
import Rules from "./MenuItems/Rules/RulesPage";
import Faq from "./MenuItems/Faq/FaqPage.jsx";
import CookieSettings from "../CookieSettings/CookieSettings";
import TermsConditionsSelector from "./MenuItems/TermsConditionsSelector/TermsConditionsSelector.jsx";

const Menu = (props) => {
  const { showMenu, setShowMenu, handleLanguageSelection, currentLocale } =
    props;
  const [activeMenuItem, setActiveMenuItem] = useState("menu");
  const [activeMenuHeading, setActiveMenuHeading] = useState("menu_heading");

  // Animation settings
  const menuAnimation = { type: "spring", damping: 22, stiffness: 150 };
  const menuVariants = {
    show: {
      left: "0%",
    },
    hide: {
      left: "-100%",
    },
  };

  // Switch for rendering menu content
  const menuSwitch = (menuItem) => {
    switch (menuItem) {
      case "menu": {
        return (
          <MenuItems
            setActiveMenuItem={setActiveMenuItem}
            setActiveMenuHeading={setActiveMenuHeading}
            currentLocale={currentLocale}
            handleLanguageSelection={handleLanguageSelection}
          />
        );
      }
      case "scoreboard":
        return <Scoreboard setActiveMenuHeading={setActiveMenuHeading} />;
      case "prizes":
        return <Prizes setActiveMenuHeading={setActiveMenuHeading} />;
      case "winners":
        return (
          <Winners
            currentLocale={currentLocale}
            setActiveMenuHeading={setActiveMenuHeading}
          />
        );
      case "rules":
        return (
          <Rules
            currentLocale={currentLocale}
            setActiveMenuHeading={setActiveMenuHeading}
          />
        );
      case "faq":
        return (
          <Faq
            currentLocale={currentLocale}
            setActiveMenuHeading={setActiveMenuHeading}
          />
        );
      case "cookies":
        return <CookieSettings setActiveMenuHeading={setActiveMenuHeading} />;
      case "termsConditions":
        return (
          <TermsConditionsSelector
            setActiveMenuHeading={setActiveMenuHeading}
          />
        );
      default:
        return (
          <MenuItems
            setActiveMenuItem={setActiveMenuItem}
            setActiveMenuHeading={setActiveMenuHeading}
          />
        );
    }
  };

  return (
    <motion.div
      className="menu-container"
      animate={showMenu ? "show" : "hide"}
      variants={menuVariants}
      transition={menuAnimation}
    >
      <div className="menu-top-container">
        {activeMenuItem === "menu" ? null : (
          <BackButton onClick={() => setActiveMenuItem("menu")} />
        )}
        <ExitCrossButton
          onClick={() => {
            setShowMenu(false);
            setActiveMenuItem("menu");
          }}
        />
      </div>

      <div className="menu-middle-container">
        <div max={24} className="menu-heading">
          <h2>
            <FormattedMessage id={activeMenuHeading} />
          </h2>
        </div>
        <div className="menu-body">{menuSwitch(activeMenuItem)}</div>
      </div>
    </motion.div>
  );
};

export default Menu;
