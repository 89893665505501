import "./styles.scss";
import React from "react";


const BlueInputField = (props) => {
  const {
    inputPlaceholder,
    setValue,
    required,
    formIsSubmitted,
    onInvalidMessage,
  } = props;

  return (
    <>
      <div className="blue-input-field-container">
        <input
          className="blue-input-field"
          type="text"
          placeholder={inputPlaceholder}
          onChange={(e) => setValue(e.target.value)}
          required={required}
          disabled={formIsSubmitted ? true : false}
          onInvalid={(e) => e.target.setCustomValidity(onInvalidMessage)}
          onInput={(e) => e.target.setCustomValidity("")}
          title={onInvalidMessage}
        ></input>
      </div>
    </>
  );
};

export default BlueInputField;
