import React, { useEffect } from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";

const WinnersPage = (props) => {
  const { setActiveMenuHeading } = props;
  useEffect(() => {
    setActiveMenuHeading("menu_winners");
  });

  return (
    <>
      <div className="winners-page-content">
        <h2>
          <FormattedMessage id="winners_consumer_heading"/>
        </h2>
        <p>
          <FormattedMessage id="winners_consumer_content"/>
        </p>

        <h2>
          <FormattedMessage id="winners_terrace_heading"/>
        </h2>
        <p>
          <FormattedMessage id="winners_terrace_content_1"/>
          <br></br>
          <FormattedMessage id="winners_terrace_content_2"/>
        </p>


      </div>
    </>
  );
};

export default WinnersPage;
