import React from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";

const WinnersResults = () => {
  return (
    <>
      <div className="main-container">
        <div className="winner-container-left">
          <div className="winner-container">
            <p>
              <FormattedMessage id="scoreboard_winners_title" />
            </p>
            <h2>Grand Café Zarautz</h2>
            <p>
              <FormattedMessage id="scoreboard_winners_may" />
            </p>
          </div>
        </div>

        <div className="winner-container-right">
          <div className="winner-container">
            <p>
              <FormattedMessage id="scoreboard_winners_title" />
            </p>
            <h2>Zeumeren Watersport</h2>
            <p>
              <FormattedMessage id="scoreboard_winners_june" />
            </p>
          </div>
        </div>

        <div className="winner-container-left">
          <div className="winner-container">
            <p>
              <FormattedMessage id="scoreboard_winners_title" />
            </p>
            <h2>Terras Maasoever</h2>
            <p>
              <FormattedMessage id="scoreboard_winners_july" />
            </p>
          </div>
        </div>

        {/* UPDATE FOR AUGUST WINNER*/}
        <div className="winner-container-right-tbc">
          <div className="winner-container">
            <p>
              <FormattedMessage id="scoreboard_winners_title" />
            </p>
            <h2>?</h2>
            <p>
              <FormattedMessage id="scoreboard_winners_august" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinnersResults;
