import "./styles.scss";
import React from "react";
import listToggle from "../../assets/img/icons/Icon_list.svg";
import mapToggle from "../../assets/img/icons/Icon_map.svg";

const MapToggle = (props) => {
  const {
    isMapView,
    setMapView,
    checked,
    setSearchParams,
    selectedVenue,
    searchParams,
  } = props;

  const onToggle = (value) => {
    setMapView(value);

    if (searchParams.get("terras")) {
      setSearchParams({
        view: value ? "map" : "list",
        terras: selectedVenue.id,
      });
    } else if (searchParams.get("search")) {
      setSearchParams({
        view: value ? "map" : "list",
        search: searchParams.get("search"),
      });
    } else {
      setSearchParams({ view: value ? "map" : "list" });
    }
  };

  return (
    <>
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name="toggleSwitch"
          id="toggleSwitch"
          checked={checked}
          onChange={(e) => onToggle(e.target.checked)}
        />
        <label className="toggle-switch-label" htmlFor="toggleSwitch">
          <span className="toggle-switch-inner"></span>
          <span className="toggle-switch-switch">
            {isMapView ? (
              <img alt="" className="toggle-img" src={listToggle} />
            ) : (
              <img alt="" className="toggle-img" src={mapToggle} />
            )}
          </span>
        </label>
      </div>
    </>
  );
};

export default MapToggle;
