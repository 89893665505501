import React from "react";
import "./styles.scss";
import blueArrow from "../../assets/img/icons/blue_arrow.svg";

const WhiteArrowButton = (props) => {
  const { onClick, style, direction } = props;

  return (
    <>
      <div className="white-arrow-button-container ">
        <button style={style} onClick={onClick} className="white-arrow-button">
          <img
            src={blueArrow}
            style={{
              transform: direction === "left" ? "" : "rotate(180deg)",
              width: "30px",
            }}
            alt="go-button"
          />
        </button>
      </div>
    </>
  );
};

export default WhiteArrowButton;
