import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import "./App.scss";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";

// Page imports

import StartPage from "./pages/start-page/StartPage";
import VotingPage from "./pages/voting-page/VotingPage";
import ThanksForVotingPage from "./pages/thanks-voting-page/ThanksForVotingPage";
import EndPage from "./pages/end-page/EndPage";

// Component imports
import Menu from "./components/Menu/Menu";
import DesktopMessage from "./components/DesktopMessage/DesktopMessage";
import CookieSettings from "./components/CookieSettings/CookieSettings";

// Video imports
import IntroAnimation_NL from "./assets/vid/IntroAnimationNL.mp4";
import CelebrationAnimation_NL from "./assets/vid/CelebrationAnimationNL.mp4";

// Background imports
import VoteAgainTomorrowBackground from "./assets/img/backgrounds/background_VoteAgainTomorrow.png";
import IntroPageBackground from "./assets/img/backgrounds/background_IntroPage.png";
// Logo
import Logo from "./assets/img/Lipton_Terrace_logo.png";

const App = () => {
  const userAgent = new UAParser();
  const userAgentData = userAgent.getResult();

  const getInitialLocale = () => {
    let savedLocale = localStorage.getItem("locale");
    if (!savedLocale) {
      return "nl-NL";
    }
    return savedLocale;
  };
  const [trafficOrigin, setTrafficOrigin] = useState("na"); //this "na" is never set, remove ??

  const checkURL = () => {
    var url = window.location.toString();
    var queryString = url.split("?");

    // ----------------------------  IF STRING EXISTS ----------------------------
    if (queryString.length > 1) {
      var pairs = queryString[1].split("&");
      for (var i in pairs) {
        var keyval = pairs[i].split("=");

        // Check if key is not already stored in session storage
        if (sessionStorage.getItem(keyval[0]) === null) {
          // If value is EMPYTY, store "no-term"; otherwise, decode and store the value
          if (keyval[1] === "") {
            sessionStorage.setItem(keyval[0], "no-term");
          } else {
            sessionStorage.setItem(keyval[0], decodeURIComponent(keyval[1]));
          }
        }

        //If the key is "utm_term", call setTrafficOrigin function with the value
        if (keyval[0].toString() === "utm_term") {
          setTrafficOrigin(keyval[1]);
        }
      }
    }
    // ---------------------------- IF STRING DOESN'T EXIST ----------------------------
    else {
      // If no query string, check if "utm_term" is stored in session storage
      if (sessionStorage.getItem("utm_term") != null) {
        // If stored, call setTrafficOrigin function with the stored value
        setTrafficOrigin(sessionStorage.getItem("utm_term"));
      } else {
        // If not stored, set "na" as the value of "utm_term" in session storage (no specific value provided)
        sessionStorage.setItem("utm_term", "na");
      }
    }

    // ---------------------------- ADDITIONAL CHECKS ----------------------------
    // If "utm_term" is an empty string, replace it with "no-term-beforefix"
    if (sessionStorage.getItem("utm_term") === "") {
      sessionStorage.setItem("utm_term", "no-term-beforefix");
    }

    // If "utm_term" is still null, "utm_" parameters detected in the URL query string,
    // but the "utm_term" parameter was missing
    // set it to "utm-tags-no-term"
    if (sessionStorage.getItem("utm_term") === null) {
      sessionStorage.setItem("utm_term", "utm-tags-no-term");
    }
  };

  // const checkURL = () => {
  //   try {
  //     const url = new URL(window.location.href);
  //     const queryParams = url.searchParams;
  //     if (queryParams.size > 0) {
  //       queryParams.forEach((value, key) => {
  //         if (!sessionStorage.getItem(key)) {
  //           sessionStorage.setItem(key, value || "no-term");
  //         }
  //       });
  //     } else {
  //       const storedTerm = sessionStorage.getItem("utm_term");
  //       if (!storedTerm) {
  //         sessionStorage.setItem("utm_term", "na");
  //       } else if (storedTerm === "") {
  //         sessionStorage.setItem("utm_term", "no-term-beforefix");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while parsing URL:", error);
  //   }
  // };

  const handleLanguageSelection = (languageCode) => {
    setCurrentLocale(languageCode);
    localStorage.setItem("locale", languageCode);
  };

  const [currentLocale, setCurrentLocale] = useState(getInitialLocale);

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const startSession = () => {
    sessionStorage.setItem("access_token", makeid(10));
  };

  const logoClick = () => {
    navigate("/stemmen");
    window.location.reload();
  };

  useEffect(() => {
    startSession();
    checkURL();
  }, [currentLocale]);

  // ------------ END PAGE set for 31st August 2024 ------------
  const Ending = () => {
    const today = new Date();
    const endDate = new Date("2024-09-01T00:01:00");

    if (today > endDate) {
      return "ended";
    } else if (
      today < endDate ||
      today === endDate ||
      today.getTime() === endDate.getTime()
    ) {
      return "active";
    }
  };

  return (
    <IntlProvider
      messages={messages[currentLocale]}
      locale={currentLocale}
      defaultLocale={LOCALES.DUTCH}
    >
      <div className="app">
        {Ending() === "active" ? (
          <>
            {userAgentData.device.type !== "mobile" &&
            userAgentData.device.type !== "tablet" ? (
              <>
                <img alt="" src={Logo} className="lipton-terrace-logo" />
                <Routes>
                  <Route path="" element={<DesktopMessage />} />
                  <Route path="/stemmen" element={<DesktopMessage />} />
                </Routes>
              </>
            ) : (
              <>
                {(window.location.pathname.startsWith("/welcome") ||
                  window.location.pathname.startsWith("/stemmen") ||
                  window.location.pathname.startsWith(
                    "/bedankt-voor-het-stemmen"
                  )) && (
                  <>
                    <img
                      alt=""
                      src={Logo}
                      className="lipton-terrace-logo"
                      onClick={logoClick}
                    />
                    <Menu
                      showMenu={showMenu}
                      setShowMenu={setShowMenu}
                      handleLanguageSelection={handleLanguageSelection}
                      currentLocale={
                        currentLocale === "nl-NL" ? "nl-NL" : "en-US"
                      }
                    />
                  </>
                )}

                <Routes>
                  <Route
                    path="/"
                    element={
                      <StartPage
                        setShowMenu={setShowMenu}
                        IntroPageBackground={IntroPageBackground}
                        IntroAnimation={IntroAnimation_NL}
                        terraceLogo={Logo}
                      />
                    }
                  />
                  <Route
                    path="/stemmen"
                    element={
                      <VotingPage
                        navigate={navigate}
                        setShowMenu={setShowMenu}
                        userAgentData={userAgentData}
                      />
                    }
                  />
                  <Route
                    path="/bedankt-voor-het-stemmen"
                    element={
                      <ThanksForVotingPage
                        setShowMenu={setShowMenu}
                        CelebrationAnimation={CelebrationAnimation_NL}
                        VoteAgainTomorrowBackground={
                          VoteAgainTomorrowBackground
                        }
                      />
                    }
                  />
                  <Route
                    path="/cookie-instellingen"
                    element={<CookieSettings />}
                  />
                </Routes>
              </>
            )}
          </>
        ) : (
          <Routes>
            <Route path="/" element={<EndPage />} />
          </Routes>
        )}
      </div>
    </IntlProvider>
  );
};

export default App;
