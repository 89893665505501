import React, { useState } from "react";
import "./styles.scss";
import HamburgerMenuButton from "../../components/HamburgerMenuButton/HamburgerMenuButton";
import { motion, useMotionValue, useTransform } from "framer-motion";
import ContestMessage from "../../components/ContestMessage/ContestMessage";
import ContestQuestionsNetherlands from "../../components/ContestQuestionsNetherlands/ContestQuestionsNetherlands";
import WhitePillButton from "../../components/WhitePillButton/WhitePillButton";
import SliderIconBar from "../../components/SliderIconBar/SliderIconBar";
import { FormattedMessage } from "react-intl";
import refreshIcon from "../../assets/img/icons/Icon_return.svg";
import { Link } from "react-router-dom";

const ThanksForVotingPage = (props) => {
  const { setShowMenu, CelebrationAnimation, VoteAgainTomorrowBackground } =
    props;
  const [emailPopupIsVisible, setEmailPopupIsVisible] = useState(false);
  const [participateInContest, setParticipateInContest] = useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [ignoredOrFinishedCompetition, setIgnoredOrFinishedCompetition] =
    useState(false);

  const liptonCampaign = () => {
    window.location.href = "https://www.liptonclub.nl/";
    window.dataLayer.push({
      event: "app-flow",
      action: "selected-lipton-club-thank-you",
    });
  };

  // POPUP ANIMATION
  const popupAnimation = { type: "spring", damping: 22, stiffness: 150 };
  const emailPopupVariants = {
    show: {
      bottom: "0%",
      display: "block",
    },
    hide: {
      bottom: "-100%",
      display: "hidden",
    },
  };
  const backgroundOverlayVariants = {
    active: {
      display: "block",
    },
    inactive: {
      display: "none",
    },
  };
  const y = useMotionValue(0);
  const input = [-400, 0, 400];
  const output = [0, 0.5, 0];
  const opacity = useTransform(y, input, output);

  return (
    <>
      <div
        className="thanks-voting-page-container"
        style={
          !ignoredOrFinishedCompetition
            ? {
                backgroundImage: `none`,
              }
            : {
                backgroundImage: `url(${VoteAgainTomorrowBackground})`,
              }
        }
      >
        <motion.div
          className={
            emailPopupIsVisible
              ? "background-dark-transparent-overlay"
              : "background-dark-transparent-overlay hidden"
          }
          animate={
            emailPopupIsVisible || participateInContest ? "active" : "inactive"
          }
          variants={backgroundOverlayVariants}
          style={{ opacity }}
        />
        <div className="thanks-voting-page-top-container">
          <HamburgerMenuButton
            onClick={() => {
              setShowMenu(true);
              window.dataLayer.push({
                event: "app-flow",
                action: "menu-opened",
              });
            }}
          />
        </div>

        {/* ------------------------------ THANKS FOR VOTING VIDEO ------------------------- */}
        <video
          playsInline
          autoPlay
          className="thanks-voting-page-animation"
          muted
          onEnded={() => {
            setEmailPopupIsVisible(true);
          }}
          style={
            ignoredOrFinishedCompetition
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <source src={CelebrationAnimation} type="video/mp4" />
        </video>

        {/* ------------------------------ COME BACK TOMORROW VIEW ------------------------- */}
        <div className="come-back-tomorrow-container">
          {ignoredOrFinishedCompetition ? (
            <div className="come-back-tomorrow-text">
              <div className="come-back-tomorrow-cup">
                <h2>
                  <FormattedMessage id="come_back_tomorrow_1" />
                </h2>
                <h1 style={{ marginTop: "-1rem" }}>
                  <FormattedMessage id="come_back_tomorrow_2" />
                </h1>
                <h2>
                  <FormattedMessage id="come_back_tomorrow_3" />
                </h2>

                <Link to="/stemmen">
                  <img alt="" className="refresh-button" src={refreshIcon} />
                </Link>
              </div>

              <div className="campaign-container">
                <p className="see_campaign">
                  {" "}
                  <FormattedMessage id="see_campaign" />
                </p>
                <WhitePillButton
                  buttonText={<FormattedMessage id="see_lipton_campaign" />}
                  onClick={liptonCampaign}
                  style={{
                    width: "auto",
                    padding: "0px 20px",
                    fontFamily: "IntroLipton-Black",
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>

        {/* ---------------------------- CONTEST MESSAGE/FORM POPUP --------------------------- */}
        <motion.div
          className="contest-popup-container"
          style={{ y }}
          animate={emailPopupIsVisible ? "show" : "hide"}
          variants={emailPopupVariants}
          transition={popupAnimation}
          drag="y"
          dragElastic={{ top: 0, bottom: 0.8 }}
          dragConstraints={{
            top: 0,
            bottom: 0,
          }}
          onDragEnd={(event, info) => {
            if (info.offset.y > 230) {
              setEmailPopupIsVisible(false);
              setIgnoredOrFinishedCompetition(true);

              setTimeout(() => {
                setParticipateInContest(false);
              }, 500);
            }
          }}
        >
          <SliderIconBar />
          {participateInContest ? (
            <ContestQuestionsNetherlands
              setEmailPopupIsVisible={setEmailPopupIsVisible}
              formIsSubmitted={formIsSubmitted}
              setFormIsSubmitted={setFormIsSubmitted}
              setParticipateInContest={setParticipateInContest}
              setIgnoredOrFinishedCompetition={setIgnoredOrFinishedCompetition}
            />
          ) : (
            <ContestMessage
              setEmailPopupIsVisible={setEmailPopupIsVisible}
              setParticipateInContest={setParticipateInContest}
            />
          )}
        </motion.div>
      </div>
    </>
  );
};

export default ThanksForVotingPage;
