import React from "react";
import "./styles.scss";

const BlueEmailInputField = (props) => {
  const { inputPlaceholder, setEmail, formIsSubmitted, onInvalidMessage } =
    props;

  return (
    <>
      <div className="blue-input-field-container">
        <input
          className="blue-input-field"
          type="email"
          placeholder={inputPlaceholder}
          onChange={(e) => setEmail(e.target.value)}
          required={true}
          disabled={formIsSubmitted ? true : false}
          onInvalid={(e) => e.target.setCustomValidity(onInvalidMessage)}
          onInput={(e) => e.target.setCustomValidity("")}
          title={onInvalidMessage}
        ></input>
      </div>
    </>
  );
};

export default BlueEmailInputField;
