import React, { useEffect } from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import tcConsumersPDF from "../../../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";

const FaqPage = (props) => {
  const { setActiveMenuHeading, currentLocale } = props;
  useEffect(() => {
    setActiveMenuHeading("menu_faq");
  });
  return (
    <>
      <div className="faq-page-content">
        <h3>
          <FormattedMessage id="faq_question_1" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_1" />
        </p>
        <br />
        <h3>
          <FormattedMessage id="faq_question_2" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_2" />
        </p>
        <br />
        <h3>
          <FormattedMessage id="faq_question_3" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_3" />
        </p>
        <br />
        <h3>
          <FormattedMessage id="faq_question_4" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_4" />
          {currentLocale === "nl-NL" && (
            <>
              <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                <FormattedMessage id="faq_answer_4_href" />
              </a>
              <FormattedMessage id="faq_answer_4_2" />
            </>
          )}
        </p>
        <br />
        <h3>
          <FormattedMessage id="faq_question_5" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_5" />
        </p>
        <br />
        <h3>
          <FormattedMessage id="faq_question_6" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_6" />
        </p>
        <br />
        <h3>
          <FormattedMessage id="faq_question_7" />
        </h3>
        <p>
          <FormattedMessage id="faq_answer_7" />
        </p>
      </div>
    </>
  );
};

export default FaqPage;
