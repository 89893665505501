import React, { useState } from "react";
import "./styles.scss";

const WhiteCheckBox = (props) => {
  const { checkboxId, required, onClick, value } = props;
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="white-checkbox">
      <input
        className="white-checkbox-input"
        type="checkbox"
        id={checkboxId}
        required={required}
        checked={isChecked}
        value={value}
        onChange={() => onClick()}
      />
      <label
        htmlFor={checkboxId}
        onClick={() => setIsChecked((prevIsChecked) => !prevIsChecked)}
      ></label>
    </div>
  );
};

export default WhiteCheckBox;
