import React, { useRef, useState } from "react";
import "./styles.scss";
import { Navigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import HamburgerMenuButton from "../../components/HamburgerMenuButton/HamburgerMenuButton";
import EnterExperienceButton from "../../components/EnterExperienceButton/EnterExperienceButton.jsx";
import tcConsumersPDF from "../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";
import rules from "../../assets/docs/ActiereglementConsumentHétLiptonTerrasvan2024.pdf";
import WhitePillButton from "../../components/WhitePillButton/WhitePillButton.jsx";

import WhiteArrowButton from "../../components/WhiteArrowButton/WhiteArrowButton";
import clubCircle from "../../assets/img/backgrounds/lipton_club_circle.png";
import terraceCircle from "../../assets/img/backgrounds/lipton_terras_circle.png";
import clubLogo from "../../assets/img/Lipton_Club_Logo.png";

const StartPage = (props) => {
  const { IntroPageBackground, IntroAnimation, setShowMenu, terraceLogo } =
    props;

  const introVideo = useRef(null);
  const hamburgerMenuRef = useRef();
  const [introGif, setIntroGif] = useState(false);
  const [gifPlaying, isgifPlaying] = useState(true);

  const navigate = useNavigate();

  const goToLiptonClub = () => {
    window.dataLayer.push({
      event: "app-flow",
      action: "selected-lipton-club",
    });
    window.location.href = "https://www.liptonclub.nl/";
  };

  const startGif = () => {
    window.dataLayer.push({
      event: "app-flow",
      action: "selected-lipton-terrace",
    });
    setIntroGif(true);
  };
  if (gifPlaying && introGif) {
    setTimeout(() => {
      isgifPlaying(false);
    }, 7000);
  }

  return (
    <>
      <div
        className="start-page-container"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {introGif ? (
          // ------------------------ INTRO GIF ------------------------
          <>
            <div className="start-page-top-container">
              <HamburgerMenuButton
                hamburgerMenuRef={hamburgerMenuRef}
                onClick={() => {
                  setShowMenu(true);
                  window.dataLayer.push({
                    event: "app-flow",
                    action: "menu-opened",
                  });
                }}
              />
            </div>
            <video
              playsInline
              ref={introVideo}
              className="start-page-background"
              muted
              autoPlay
            >
              {introGif && <source src={IntroAnimation} type="video/mp4" />}
            </video>

            <div className="enter-experience-container">
              <EnterExperienceButton enterExperienceHref={"/stemmen"} />
              {gifPlaying ? <></> : <Navigate to="/stemmen" />}
            </div>

            <span className="legal-line-container">
              <p>
                <FormattedMessage id="legal_line" />
                <a href={rules} target="_blank" rel="noreferrer">
                  <FormattedMessage id="legal_href" />
                </a>
                <FormattedMessage id="legal_line3" />
                <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                  <FormattedMessage id="legal_href2" />
                </a>
                <FormattedMessage id="legal_line2" />
              </p>
            </span>
          </>
        ) : (
          // ------------------------ START PAGE ------------------------
          <div className="landingg-page-container">
            <h1>
              <FormattedMessage id="landing_page_heading" />
            </h1>

            <div className="terras">
              <img src={terraceCircle} alt="lipton-terrace-2024" />
              <div className="info-container">
                <p>
                  <FormattedMessage id="landing_terrace" />
                </p>
                <div className="button-container">
                  <img src={terraceLogo} alt="terrace-logo" />
                  <WhiteArrowButton onClick={() => startGif()} />
                </div>
              </div>
            </div>
            <div className="club">
              <img src={clubCircle} alt="lipton-club-2024" />
              <div className="info-container">
                <p>
                  <FormattedMessage id="landing_club" />
                </p>
                <div className="button-container">
                  <img src={clubLogo} alt="club-logo" />
                  <WhiteArrowButton onClick={goToLiptonClub} direction="left" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StartPage;
