import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import MenuLanguageSelector from "./MenuLanguageSelector/MenuLanguageSelector";
import Accordion from "../Accordion";
import "../styles.scss";
import tcBusinessPDF from "../../../assets/docs/ActievoorwaardenBusinessHétLiptonTerrasvan2024.pdf";
import tcConsumersPDF from "../../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";
import rules from "../../../assets/docs/ActiereglementConsumentHétLiptonTerrasvan2024.pdf";

const MenuItems = (props) => {
  const { setActiveMenuItem, setActiveMenuHeading } = props;
  const { handleLanguageSelection } = props;
  const onetrustButtonRef = useRef();
  useEffect(() => {
    setActiveMenuHeading("menu_heading");
  });
  // const showOnetrustScreen = () => {
  //   console.log("click");
  //   onetrustButtonRef.current.click();
  // };
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (index) => {
    setIsOpen((prevIndex) => (prevIndex === index ? false : index));
  };

  return (
    <>
      <button
        id="ot-sdk-btn"
        className="ot-sdk-show-settings bottom"
        ref={onetrustButtonRef}
        style={{ display: "none" }}
      />
      <ul className="menu-items">
        <li
          className="menu-item-container"
          onClick={() => {
            setActiveMenuItem("scoreboard");
            window.dataLayer.push({
              event: "app-flow",
              action: "menu-opened-scoreboard",
            });
          }}
        >
          <span>
            <h4 className="menu-item-title">
              <FormattedMessage id="menu_scoreboard" />
            </h4>
          </span>
        </li>

        {/** "THE COMPETITION" **/}
        <div className="accordion-style">
          <Accordion
            accordion_title={<FormattedMessage id="menu_competition" />}
            accordion_content={
              <div>
                {/** "THE COMPETITION" - PRIZES */}
                <li
                  className="menu-sub-item"
                  onClick={() => {
                    setActiveMenuItem("prizes");
                    window.dataLayer.push({
                      event: "app-flow",
                      action: "menu-opened-prizes",
                    });
                  }}
                >
                  <span>
                    <h4>
                      <FormattedMessage id="menu_prizes" />
                    </h4>
                  </span>
                </li>

                {/** "THE COMPETITION" - WINNERS */}
                <li
                  className="menu-sub-item"
                  onClick={() => {
                    setActiveMenuItem("winners");
                    window.dataLayer.push({
                      event: "app-flow",
                      action: "menu-opened-winners",
                    });
                  }}
                >
                  <span>
                    <h4>
                      <FormattedMessage id="menu_winners" />
                    </h4>
                  </span>
                </li>

                {/** "THE COMPETITION" - RULES */}
                <li
                  className="menu-sub-item"
                  onClick={() => {
                    setActiveMenuItem("rules");
                    window.dataLayer.push({
                      event: "app-flow",
                      action: "menu-opened-rules",
                    });
                  }}
                >
                  <span>
                    <h4>
                      <FormattedMessage id="menu_rules" />
                    </h4>
                  </span>
                </li>
              </div>
            }
            isActive={isOpen === 0}
            onClick={() => toggle(0)}
          />
        </div>

        {/** ACTIEVOORWAARDEN */}
        <div className="accordion-style">
          <Accordion
            accordion_title={<FormattedMessage id="menu_terms_conditions" />}
            accordion_content={
              <div>
                {/** COOKIES */}
                <li className="menu-sub-item">
                  <span>
                    <h4>
                      <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                        <FormattedMessage id="menu_terms_conditions_consumers" />
                      </a>
                    </h4>
                  </span>
                </li>

                {/** REGLEMENT */}
                <li className="menu-sub-item">
                  <span>
                    <h4>
                      <a href={tcBusinessPDF} target="_blank" rel="noreferrer">
                        <FormattedMessage id="menu_terms_conditions_business" />
                      </a>
                    </h4>
                  </span>
                </li>
              </div>
            }
            isActive={isOpen === 3}
            onClick={() => toggle(3)}
          />
        </div>

        {/* FAQs */}
        <li
          className="menu-item-container"
          onClick={() => {
            setActiveMenuItem("faq");
            window.dataLayer.push({
              event: "app-flow",
              action: "menu-opened-faq",
            });
          }}
        >
          <span>
            <h4 className="menu-item-title">
              <a
                // href="" target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="menu_faq" />
              </a>
            </h4>
          </span>
        </li>

        {/** REGLEMENT */}
        <li className="menu-item-container">
          <div className="menu-item">
            <span>
              <h4 className="menu-item-title">
                <a href={rules} target="_blank" rel="noreferrer">
                  <FormattedMessage id="menu_reglement_lipton" />
                </a>
              </h4>
            </span>
            <span className="menu-item-icon">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          </div>
        </li>
      </ul>
      <div className="language-container">
        <MenuLanguageSelector
          handleLanguageSelection={handleLanguageSelection}
          setActiveMenuHeading={setActiveMenuHeading}
          setActiveMenuItem={setActiveMenuItem}
        />
      </div>
    </>
  );
};

export default MenuItems;
