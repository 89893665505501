import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const HamburgerMenuButton = (props) => {
  const { onClick, hamburgerMenuRef } = props;

  return (
    <div ref={hamburgerMenuRef} onClick={onClick} className="nav-icon-wrapper">
      <FontAwesomeIcon icon={faBars} className="nav-icon" />
    </div>
  );
};

export default HamburgerMenuButton;
