import React from "react";
import { FormattedMessage } from "react-intl";
import "./styles.scss";
import WhitePillButton from "../WhitePillButton/WhitePillButton";

const EnterExperienceButton = (props) => {
  const { enterExperienceHref } = props;

  const enterExperience = () => {
    window.location.href = enterExperienceHref;
  };

  return (
    <div className="enter-experience">
      <WhitePillButton
        buttonText={<FormattedMessage id="enter_button" />}
        onClick={() => enterExperience()}
        style={{
          width: "auto",
          padding: "0px 30px",
          fontFamily: "IntroLipton-Black",
        }}
      />
    </div>
  );
};

export default EnterExperienceButton;
