import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import "./styles.scss";
import VenueSearchField from "../../components/VenueSearchField/VenueSearchField";
import ListView from "../../components/ListView/ListView";
import MapView from "../../components/MapView/MapView";
import InitialSearchMenu from "../../components/InitialSearchMenu/InitialSearchMenu";
import HamburgerMenuButton from "../../components/HamburgerMenuButton/HamburgerMenuButton";
import MapToggle from "../../components/MapToggle/MapToggle";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import TerraceLink from "../../components/TerraceLink/TerraceLink";

const VotingPage = (props) => {
  const { navigate, setShowMenu, retrieveAccessToken, userAgentData } = props;

  const intl = useIntl();
  const hamburgerMenuRef = useRef();
  const [selectedVenue, setSelectedVenue] = useState({});
  const [venues, setVenues] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [showNearestTerrace, setShowNearestTerrace] = useState(false);
  const [hasLocationPermission, setHasLocationPermission] = useState(false);
  const [initialPermissionCheck, setInitialPermissionCheck] = useState(true);
  const [notification, setNotification] = useState("");
  const [hasVoted, setHasVoted] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const [searchBarPlaceholder, setSearchBarPlaceholder] = useState(""); // check with client before removing fully
  const [withButton, isWithButton] = useState(false);

  let firstTimeNoPerm = true;
  if (hasLocationPermission === false) {
    firstTimeNoPerm = false;
  }

  useEffect(() => {
    if (venues.length !== 0) setHasLocationPermission(true);
    console.log("hasLocationPermission:", hasLocationPermission);
  }, [venues]);

  // SEARCH PARAMS
  let [searchParams, setSearchParams] = useSearchParams({
    search: "",
    terras: "",
    view: "",
  });
  const search = searchParams.get("search");
  const terras = searchParams.get("terras");
  const view = searchParams.get("view");

  const searchVenue = async (searchInputValue) => {
    var loweredSearch = searchInputValue.toLowerCase();
    if (!searchInputValue || searchInputValue.trim() === "") return;
    if (
      !searchInputValue ||
      loweredSearch === "café" ||
      loweredSearch === "bar" ||
      loweredSearch === "cafe" ||
      loweredSearch.length < 3
    ) {
      if (
        loweredSearch === "cafe" ||
        loweredSearch === "bar" ||
        loweredSearch === "café" ||
        loweredSearch === "caf"
      ) {
        setNotification(intl.formatMessage({ id: "wrong_input" }));
      } else {
        setNotification(intl.formatMessage({ id: "short_input" }));
      }
      return;
    }
    setNotification("");
    // setLoading(true);
    const response = await axios({
      method: "get",
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/api/venues/search-venue?search-term=${searchInputValue}&map-search=${showMap}`,
    }).catch((error) => {
      console.log(error);
    });
    // Retrieve new access token if current is expired (lifetime is 10 min.)
    if (response.status === 401) {
      console.log(response.data);
      await retrieveAccessToken();
      return searchVenue();
    }
    await setVenues(response.data);
    // setLoading(false);

    if (showMap) {
      setSearchParams({ view: "map", search: searchInputValue });
    } else {
      setSearchParams({ view: "list", search: searchInputValue });
    }
  };

  const searchVenueByClick = async (searchValue) => {
    var loweredSearch = searchValue.toLowerCase();
    if (!searchValue || searchValue.trim() === "") return;
    if (
      !searchValue ||
      loweredSearch === "café" ||
      loweredSearch === "bar" ||
      loweredSearch === "cafe" ||
      loweredSearch === "caf" ||
      loweredSearch.length < 3
    ) {
      if (
        loweredSearch === "cafe" ||
        loweredSearch === "bar" ||
        loweredSearch === "café" ||
        loweredSearch === "caf"
      ) {
        setNotification(intl.formatMessage({ id: "wrong_input" }));
      } else {
        setNotification(intl.formatMessage({ id: "short_input" }));
      }
      return;
    }
    // setLoading(true);
    const response = await axios({
      method: "get",
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/api/venues/search-venue?search-term=${searchValue}`,
    }).catch((error) => {
      console.log(error);
    });
    // Retrieve new access token if current is expired (lifetime is 10 min.)
    if (response.status === 401) {
      console.log(response.data);
      await retrieveAccessToken();
      return searchVenue();
    }
    await setVenues(response.data);
    // setLoading(false);

    if (response.data.length === 0) {
      console.log("no venues");
    }
    if (sessionStorage.getItem("user-started-search") === null) {
      window.dataLayer.push({
        event: "app-flow",
        action: "user-searched-for-venue",
      });
      sessionStorage.setItem("user-started-search", "true");
    }
    window.dataLayer.push({
      event: "app-flow",
      action: "search-bar-used",
    });

    if (showMap) {
      setSearchParams({ view: "map", search: searchValue });
    } else {
      setSearchParams({ view: "list", search: searchValue });
    }
  };

  const getDeepLinkVenue = async (view) => {
    try {
      axios({
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + `/api/venues/${terras}`,
      }).then((response) => {
        setSelectedVenue(response.data);
        setVenues([response.data]);
        // setSearchBarPlaceholder(response.data.venue_name);
      });
    } catch (e) {
      console.log(e);
    }

    if (view) {
      setSearchParams({ view: view, terras: terras });
    } else {
      setSearchParams({ terras: terras });
    }
  };

  useEffect(() => {
    setSearchBarPlaceholder(
      intl.formatMessage({
        id: "search_bar_placeholder",
      })
    );

    // PARAM RULES OVER STATES
    //from any view
    if (search !== "") {
      searchVenue(search);
      setInitialSearch(false);
    }
    // show deep link page
    else if (terras !== "" && view === "") {
      setInitialSearch(false);
      setShowMap(false);
      getDeepLinkVenue();
      isWithButton(false);
    }
    // show map
    else if (view === "map") {
      setShowMap(true);
      setInitialSearch(false);
      isWithButton(false);
      if (terras !== "") {
        getDeepLinkVenue("map");
      }
    }
    // show list
    else if (view === "list") {
      setShowMap(false);
      setInitialSearch(false);
      isWithButton(false);
      if (terras !== "") {
        getDeepLinkVenue("list");
      }
    }
  }, [search, terras, view]);

  return (
    <div className="voting-page-container">
      {initialSearch ? (
        // ----------------------------- SHOW INITIAL SEARCH -----------------------------
        <>
          <div className="voting-page-top-container begin">
            <HamburgerMenuButton
              hamburgerMenuRef={hamburgerMenuRef}
              onClick={() => {
                setShowMenu(true);
                window.dataLayer.push({
                  event: "app-flow",
                  action: "menu-opened",
                });
              }}
            />
          </div>
          <div className="voting-initial-search">
            <InitialSearchMenu
              isHomepage={true}
              initialSearch={setInitialSearch}
              retrieveAccessToken={retrieveAccessToken}
              notification={notification}
              setNotification={setNotification}
              setVenues={setVenues}
              setSearchParams={setSearchParams}
              searchVenueByClick={searchVenueByClick}
              searchBarPlaceholder={searchBarPlaceholder}
            />
          </div>
        </>
      ) : (
        // --- voting page header for Map, List and Terrace view ---
        <>
          <div className="voting-page-top-container">
            <HamburgerMenuButton
              hamburgerMenuRef={hamburgerMenuRef}
              onClick={() => {
                setShowMenu(true);
                window.dataLayer.push({
                  event: "app-flow",
                  action: "menu-opened",
                });
              }}
            />
            <MapToggle
              isMapView={showMap}
              setMapView={setShowMap}
              checked={showMap}
              setSearchParams={setSearchParams}
              selectedVenue={selectedVenue}
              searchParams={searchParams}
            />
            <VenueSearchField
              withButton={withButton}
              showMap={showMap}
              retrieveAccessToken={retrieveAccessToken}
              setNotification={setNotification}
              setVenues={setVenues}
              setSearchParams={setSearchParams}
              searchVenue={searchVenue}
              searchVenueByClick={searchVenueByClick}
              searchBarPlaceholder={searchBarPlaceholder}
            />
          </div>

          {showMap ? (
            // ----------------------------- MAP VIEW -----------------------------
            <>
              {!hasLocationPermission && !initialPermissionCheck && (
                <div className="location-error-container">
                  <div className="error-message">
                    <p>
                      <FormattedMessage id="permission_denied_2" />
                    </p>
                  </div>
                </div>
              )}
              {notification !== "" && hasLocationPermission && (
                <div className={notification ? "error-message" : null}>
                  <p>{notification}</p>
                </div>
              )}
              {hasVoted === true && (
                <div className="error-message">
                  <p>
                    <FormattedMessage id="has_voted" />
                  </p>
                </div>
              )}
              {venues.length === 0 && hasLocationPermission && (
                <div className="error-message">
                  <p>
                    <FormattedMessage id={"venue_not_found"} />
                  </p>
                </div>
              )}
              <MapView
                navigate={navigate}
                selectedVenue={selectedVenue}
                setSelectedVenue={setSelectedVenue}
                showNearestTerrace={showNearestTerrace}
                setShowNearestTerrace={setShowNearestTerrace}
                setShowMap={setShowMap}
                retrieveAccessToken={retrieveAccessToken}
                userAgentData={userAgentData}
                venues={venues}
                setVenues={setVenues}
                setHasLocationPermission={setHasLocationPermission}
                hasLocationPermission={hasLocationPermission}
                initialPermissionCheck={initialPermissionCheck}
                setInitialPermissionCheck={setInitialPermissionCheck}
                trafficOrigin={sessionStorage.getItem("utm_term")}
                setNotification={setNotification}
                notification={notification}
                setHasVoted={setHasVoted}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                terras={terras}
              />
            </>
          ) : view === "list" ? (
            // ----------------------------- LIST VIEW -----------------------------
            <>
              {notification !== "" && (
                <div className={notification ? "error-message" : null}>
                  <p>{notification}</p>
                </div>
              )}
              {hasVoted === true && (
                <div className="error-message">
                  <p>
                    <FormattedMessage id="has_voted" />
                  </p>
                </div>
              )}
              {venues.length === 0 && (
                <div className="error-message">
                  <p>
                    <FormattedMessage id={"venue_not_found"} />
                  </p>
                </div>
              )}
              <ListView
                venues={venues}
                navigate={navigate}
                selectedVenue={selectedVenue}
                setSelectedVenue={setSelectedVenue}
                showNearestTerrace={showNearestTerrace}
                retrieveAccessToken={retrieveAccessToken}
                userAgentData={userAgentData}
                hasLocationPermission={hasLocationPermission}
                firstTimeWithoutPermission={firstTimeNoPerm}
                trafficOrigin={sessionStorage.getItem("utm_term")}
                setNotification={setNotification}
                notification={notification}
                setHasVoted={setHasVoted}
              />
            </>
          ) : (
            terras !== "" &&
            view === "" && (
              // ----------------------------- TERRACE VIEW -----------------------------
              <>
                {notification !== " " && (
                  <div className={notification ? "error-message" : null}>
                    <p>{notification}</p>
                  </div>
                )}
                {hasVoted === true && (
                  <div className="error-message">
                    <p>
                      <FormattedMessage id="has_voted" />
                    </p>
                  </div>
                )}
                <TerraceLink
                  navigate={navigate}
                  setShowMenu={setShowMenu}
                  userAgentData={userAgentData}
                  trafficOrigin={sessionStorage.getItem("utm_term")}
                  showMap={showMap}
                  setShowMap={setShowMap}
                  setInitialSearch={setInitialSearch}
                  initialSearch={initialSearch}
                  selectedVenue={selectedVenue}
                  setSelectedVenue={setSelectedVenue}
                  setNotification={setNotification}
                  notification={notification}
                  setHasVoted={setHasVoted}
                />
              </>
            )
          )}
        </>
      )}
    </div>
  );
};

export default VotingPage;
