import React from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import liptonLogo from "../../assets/img/logo192.png";
import liptonArcadeQR from "../../assets/img/DesktopQR.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

// links
import rules from "../../assets/docs/ActiereglementConsumentHétLiptonTerrasvan2024.pdf";
import tcConsumersPDF from "../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";
import tcBusinessPDF from "../../assets/docs/ActievoorwaardenBusinessHétLiptonTerrasvan2024.pdf";

const DesktopMessage = () => {
  return (
    <>
      <div className="desktop-message-container">
        <div className="desktop-message-content">
          <img id="lipton-logo" src={liptonLogo} alt="lipton-logo" />
          <img id="lipton-qr-code" src={liptonArcadeQR} alt="lipton-qr" />
          <h1>
            <span>
              <FormattedMessage id="desktop_redirect_message" /> <br />
            </span>
          </h1>
          <h1>
            <span style={{ marginTop: "1rem" }}>
              <FormattedMessage id="desktop_redirect_scan" />
            </span>
          </h1>
        </div>
        <div className="desktop-footer">
          <a
            href="https://www.liptonicetea.nl/home.html"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="desktop_lipton" />{" "}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
          <a href={rules} target="_blank" rel="noreferrer">
            <FormattedMessage id="desktop_rules" />{" "}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
          <div className="terms-conditions">
            <p>
              <FormattedMessage id="desktop_terms_conditions" />
            </p>
            <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
              <FormattedMessage id="desktop_consumer" />{" "}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
            <a href={tcBusinessPDF} target="_blank" rel="noreferrer">
              <FormattedMessage id="desktop_terraces" />{" "}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopMessage;
