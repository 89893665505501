import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import MarkerImg from "../../assets/img/icons/Lipton_marker.png";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import MarkerImgFace from "../../assets/img/icons/Lipton_marker.png";
import ClipLoader from "react-spinners/ClipLoader";

const markerFaces = [MarkerImgFace];

function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

const Map = (props) => {
  const {
    selectedVenue,
    setSelectedVenue,
    setVenueSelectionIsVisible,
    venues,
    positionSucceedCallback,
    positionFailedCallback,
    hasLocationPermission,
    searchParams,
  } = props;

  const isEmpty = !venues || venues.length === 0;
  const refMap = useRef(HTMLDivElement);
  const [map, setMap] = useState();
  const [isLoading, setIsLoading] = useState(true);

  let userCenter = { lat: 52, lng: 5 };
  const prevMarkersRef = useRef([]);
  const prevMarkerRef = useRef();
  const zIndexRef = useRef();

  new MarkerClusterer({ map, prevMarkersRef });

  let longitude = 5;
  let latitude = 52;
  let zoom = 7;

  const ClosedMarker = {
    url: MarkerImg,
    scaledSize: new window.google.maps.Size(71, 92),
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function clearMarkers(markers) {
    for (let m of markers) {
      m.setMap(null);
    }
  }

  function createMarker(venue, position, map, markerRef) {
    return new window.google.maps.Marker({
      position: position,
      map: map,
      icon: ClosedMarker,
      clickable: true,
      cursor: "pointer",
      title: "Lipton locatie",
      venue,
    });
  }

  function createPopUpWindows(venue, marker, position, map) {
    marker.addListener("click", () => {
      // If another marker is already open, reset it
      if (prevMarkerRef.current) {
        prevMarkerRef.current.setIcon(ClosedMarker);
        prevMarkerRef.current.setZIndex(zIndexRef.current);
      }

      let NewMarker = {
        url: markerFaces[getRandomInt(0)],
        scaledSize: new window.google.maps.Size(95, 122),
      };

      setSelectedVenue(venue);
      setVenueSelectionIsVisible(true);
      marker.setIcon(NewMarker);

      zIndexRef.current = marker.getZIndex();
      marker.setZIndex(100000);

      prevMarkerRef.current = marker;

      if (sessionStorage.getItem("has-selected-venue") === null) {
        window.dataLayer.push({
          event: "app-flow",
          action: "selected-venue",
        });
        sessionStorage.setItem("has-selected-venue", "true");
      }
      // setSearchParams({ view: "map", terras: venue.id });

      window.dataLayer.push({
        event: "app-flow",
        action: "selected-venue-on-map",
      });
    });
  }

  useEffect(() => {
    if (!isEmpty && venues.length > 0) {
      // zoom to the first marker on load
      longitude = parseFloat(venues[0].longitude);
      latitude = parseFloat(venues[0].latitude);
      zoom = 16;
      setIsLoading(false);
    }

    if (selectedVenue.longitude) {
      // zoom to selected venue
      longitude = parseFloat(selectedVenue.longitude);
      latitude = parseFloat(selectedVenue.latitude);
      zoom = 16;
      setIsLoading(false);
    }

    if (longitude === 5 && latitude === 52) {
      setIsLoading(true); // continue to show loading while looking for first marker ??
    }
  }, [selectedVenue, isEmpty, venues]);

  useEffect(() => {
    if (refMap.current && !map) {
      setMap(
        new window.google.maps.Map(refMap.current, {
          mapId: "e540a20e263997b", // ID which holds the custom map skin
          zoom: 7,
          minZoom: 4,
          center: { lat: 52, lng: 5 },
          disableDefaultUI: true,
        })
      );
    }
  }, [refMap, map, userCenter, zoom, prevMarkersRef]);

  useEffect(() => {
    if (refMap.current && map) {
      map.addListener("click", () => {
        if (prevMarkerRef.current) {
          prevMarkerRef.current.setZIndex(zIndexRef.current);
          prevMarkerRef.current.setIcon(ClosedMarker);
        }
        setSelectedVenue({});
        setVenueSelectionIsVisible(false);

        prevMarkerRef.current = null;
        zIndexRef.current = null;
      });

      window.google.maps.event.addListenerOnce(map, "tilesloaded", function () {
        navigator.geolocation.getCurrentPosition(
          positionSucceedCallback,
          () => {
            setIsLoading(false);
            positionFailedCallback();
          }
        );
      });
    }
  }, [refMap, map]);

  useEffect(() => {
    if (map && !isEmptyObj(selectedVenue)) {
      map.setOptions({
        center: { lat: latitude, lng: longitude },
        disableDefaultUI: true,
        minZoom: 8,
        zoom: zoom,
      });
    }
  }, [selectedVenue, map]);

  useEffect(() => {
    if (map && props.venues) {
      new window.google.maps.event.trigger(map, "click");
      //clear prev markers
      clearMarkers(prevMarkersRef.current);

      let length = venues.length;
      if (length > 15) length = 15;
      //render markers
      if (!isEmpty) {
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < length; i++) {
          const marker = createMarker(
            props.venues[i],
            {
              lat: parseFloat(props.venues[i].latitude),
              lng: parseFloat(props.venues[i].longitude),
            },
            map
          );
          prevMarkersRef.current.push(marker);
          createPopUpWindows(
            props.venues[i],
            marker,
            {
              lat: parseFloat(props.venues[i].latitude),
              lng: parseFloat(props.venues[i].longitude),
            },
            map
          );
          bounds.extend(marker.position);
        }
        map.fitBounds(bounds);
      }
      if (length === 1 && searchParams.get("terras")) {
        new window.google.maps.event.trigger(
          prevMarkersRef.current[0],
          "click"
        );
      }
    }
  }, [props.venues, map]);

  return (
    <div>
      {isLoading && hasLocationPermission && (
        <>
          <div className="map-overlay">
            <div className="venue-search-loading-container">
              <ClipLoader color={"rgb(51, 145, 233)"} size={50} />
            </div>
          </div>
        </>
      )}
      <div ref={refMap} className="google-map-wrapper" />
    </div>
  );
};

export default Map;
