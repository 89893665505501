import React, { useState } from "react";
import "./styles.scss";
import axios from "axios";
import WhitePillButton from "../WhitePillButton/WhitePillButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDebounce } from "../../hooks/debounceHook";
import { FormattedMessage, useIntl } from "react-intl";

const VenueSearchField = (props) => {
  const {
    withButton,
    isHomepage,
    showMap,
    retrieveAccessToken,
    notification,
    setNotification,
    setVenues,
    setSearchParams,
    searchVenue,
    searchVenueByClick,
    searchBarPlaceholder,
  } = props;

  const intl = useIntl();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [buttonSearchInputValue, setButtonSearchInputValue] = useState("");

  const handleSearchWithButton = (e) => {
    e.preventDefault();
    searchVenueByClick(buttonSearchInputValue);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchVenueByClick(searchInputValue);
  };

  const debounce = async (tera) => {
    var loweredSearch = searchInputValue.toLowerCase();
    if (!searchInputValue || searchInputValue.trim() === "") return;
    if (
      !searchInputValue ||
      loweredSearch === "café" ||
      loweredSearch === "bar" ||
      loweredSearch === "cafe" ||
      loweredSearch.length < 3
    ) {
      if (
        loweredSearch === "cafe" ||
        loweredSearch === "bar" ||
        loweredSearch === "café" ||
        loweredSearch === "caf"
      ) {
        setNotification(intl.formatMessage({ id: "wrong_input" }));
      } else {
        setNotification(intl.formatMessage({ id: "short_input" }));
      }
      return;
    }
    if (isHomepage) {
      setNotification("");
      return;
    }

    setNotification("");
    const response = await axios({
      method: "get",
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/api/venues/search-venue?search-term=${searchInputValue}&map-search=${showMap}`,
    }).catch((error) => {
      console.log(error);
    });
    // Retrieve new access token if current is expired (lifetime is 10 min.)
    if (response.status === 401) {
      await retrieveAccessToken();
      return searchVenue();
    }
    await setVenues(response.data);
    // setLoading(false);

    if (showMap) {
      setSearchParams({ view: "map", search: searchInputValue });
    } else {
      setSearchParams({ view: "list", search: searchInputValue });
    }
  };

  //called on every change
  useDebounce(searchInputValue, 1000, debounce);

  return (
    <>
      <div className="venue-search-bar-container">
        <div className="venue-search-input-container">
          {searchInputValue === "" ? (
            <span className="venue-search-input-icon">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
          ) : (
            <span
              className="venue-search-input-icon"
              onClick={() => {
                setSearchInputValue("");
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
          {withButton && (
            <form action="#" onSubmit={handleSearchWithButton}>
              <input
                className={
                  isHomepage ? "venue-search-input home" : "venue-search-input"
                }
                placeholder={searchBarPlaceholder}
                value={searchInputValue}
                onChange={(e) => {
                  setButtonSearchInputValue(e.target.value);
                  setSearchInputValue(e.target.value);
                }}
              />
              <input
                type="submit"
                style={{
                  position: "absolute",
                  left: "-1000px",
                }}
              />
            </form>
          )}

          {!withButton && (
            <form action="#" onSubmit={handleSearch}>
              <input
                className="venue-search-input"
                placeholder={searchBarPlaceholder}
                value={searchInputValue}
                title={"hi"}
                onChange={(e) => {
                  setSearchInputValue(e.target.value);
                }}
              />
              <input
                type="submit"
                style={{
                  position: "absolute",
                  left: "-1000px",
                }}
              />
            </form>
          )}
        </div>
      </div>
      {withButton && (
        <div className="with-button-container">
          <div className="search-error">
            <p>{notification}</p>
          </div>

          <div className="search-button-container">
            <WhitePillButton
              buttonText={<FormattedMessage id="go_button" />}
              onClick={() => searchVenueByClick(buttonSearchInputValue)}
              style={{
                height: "40px",
                width: "150px",
                paddingLeft: "80px",
                paddingRight: "80px",
                margin: "20px",
              }}
              justifyEnd={"end"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VenueSearchField;
