import React from "react";
import "./styles.scss";

const SliderIconBar = () => {
  return (
    <>
      <div className="slider-icon-bar-container">
        <div className="slider-icon-bar" />
      </div>
    </>
  );
};

export default SliderIconBar;
