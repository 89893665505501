// This module retrieves the current date
import dayjs from "dayjs";

const getCurrentDate = () => {
  const dayOfMonth = dayjs().get("date");
  const month = dayjs().get("month");
  const year = dayjs().get("year");

  const fullDate = `${dayOfMonth}/${month + 1}/${year}`;
  return fullDate;
};

export default getCurrentDate;
