import "./styles.scss";
import WhitePillButton from "../WhitePillButton/WhitePillButton";
import { Textfit } from "react-textfit";
import { FormattedMessage } from "react-intl";
import icon from "../../assets/img/icons/Lipton_marker_23.png";

const ListViewElement = (props) => {
  const { venue, isSelected, onClick } = props;

  return (
    <>
      <div className={!isSelected ? "listItem" : "listItem selected"}>
        <div className="listIcon">
          <img alt="" src={icon} />
        </div>
        <div className="card-content">
          <Textfit className={"venue-name"} mode="multi" min={6}>
            <span
              style={{
                fontFamily: "Lipton-Highlight",
              }}
            >
              {venue.venue_name}
            </span>
          </Textfit>
          <p className="card-copy">
            {venue.street} {venue.housenumber}, {venue.city}
          </p>
        </div>
        <div className="listItemButton">
          {isSelected ? (
            <WhitePillButton
              buttonText={<FormattedMessage id="vote_button" />}
              onClick={() => onClick()}
            />
          ) : (
            <WhitePillButton
              buttonText={<FormattedMessage id="pick_me" />}
              onClick={() => onClick(venue)}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ListViewElement;
