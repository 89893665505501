import React, { useState, useEffect } from "react";
import "./styles.scss";
import ListViewElement from "../ListViewElement/ListViewElement";
import axios from "axios";
import getCurrentDate from "../../utils/date";
import { FormattedMessage } from "react-intl";

const ListView = (props) => {
  const {
    selectedVenue,
    navigate,
    retrieveAccessToken,
    userAgentData,
    setSelectedVenue,
    venues,
    hasLocationPermission,
    trafficOrigin,
    setNotification,
    setHasVoted,
  } = props;

  const isEmpty = !venues || venues.length === 0 || venues === "empty";
  let dateLastVoted = localStorage.getItem("date_last_voted");
  let currentDate = getCurrentDate();
  const [publicIp, setPublicIp] = useState("000000000");

  const voteData = {
    venue_name: selectedVenue.venue_name,
    secret_ip: publicIp,
    session_token: sessionStorage.getItem("access_token"),
    os_version: userAgentData.os.name,
    browser: userAgentData.browser.name,
    browser_version: userAgentData.browser.version,
    latitude: selectedVenue.latitude,
    longitude: selectedVenue.longitude,
    venue_id: selectedVenue.id,
    province: selectedVenue.province,
    traffic_origin: trafficOrigin || "na",
  };

  const processVote = async () => {
    // Check if user has already voted based on date
    if (dateLastVoted === currentDate) {
      setHasVoted(true);
      window.dataLayer.push({
        event: "app-flow",
        action: "vote-attempt-within-24hrs",
      });
      return;
    }

    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + `/api/votes`,
        data: voteData,
      });

      // Retrieve new access token if current is expired (lifetime is 10 mins.)
      if (response.status === 401) {
        retrieveAccessToken();
        return processVote();
      }

      if (response.status === 422) {
        console.log("yes");
      }

      if (response.status === 200) {
        localStorage.setItem("date_last_voted", currentDate);
        window.dataLayer.push({
          event: "app-flow",
          action: "user-has-voted",
        });
        return navigate("/bedankt-voor-het-stemmen");
      } else {
        // return console.log(response.status);
      }
    } catch (e) {
      if (e.toString() === "Error: Request failed with status code 422") {
        if (localStorage.getItem("locale") === "nl-NL") {
          setNotification(
            "Oeps! Er lijkt iets fout te gaan, refresh de pagina en probeer het snel nog eens! (422)"
          );
        } else {
          setNotification(
            "Oops! Something went wrong, click on the logo to refresh the page and try it again. (422)"
          );
        }
      }
    }
  };

  const selectVenue = (venueName) => {
    setSelectedVenue(venueName);
    if (sessionStorage.getItem("has-selected-venue") === null) {
      window.dataLayer.push({
        event: "app-flow",
        action: "selected-venue",
      });
      sessionStorage.setItem("has-selected-venue", "true");
    }
    window.dataLayer.push({
      event: "app-flow",
      action: "selected-venue-on-list",
    });
  };

  // Retrieve user's public IP
  useEffect(() => {
    axios
      .get("https://api64.ipify.org?format=json")
      .then((result) => {
        setPublicIp(result.data.ip);
      })
      .catch((e) => {
        console.log(
          `Something went wrong while retrieving the user's public IP: ${e}`
        );
      });
  }, []);

  return (
    <>
      <div className="full-list-container"></div>
      {!isEmpty ? (
        <>
          <div className="list-view-container">
            <ul>
              {venues.map((venue, index) => {
                return (
                  <li key={index}>
                    {selectedVenue.venue_name === venue.venue_name ? (
                      <ListViewElement
                        onClick={() => processVote()}
                        venue={venue}
                        isSelected
                      />
                    ) : (
                      <ListViewElement
                        venue={venue}
                        selectedVenue={selectedVenue}
                        onClick={() => selectVenue(venue)}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      ) : (
        <p>
          {hasLocationPermission && (
            <FormattedMessage
              id={
                hasLocationPermission ? "venue_not_found" : "permission_denied"
              }
            />
          )}
        </p>
      )}
    </>
  );
};

export default ListView;
