import React, { useEffect } from "react";
import "./styles.scss";

const CookieSettings = (props) => {
  const { setActiveMenuHeading } = props;

  useEffect(() => {
    if (setActiveMenuHeading) {
      setActiveMenuHeading("menu_cookies");
    }
  });

  return (
    <>
      <div className="cookies">
        <div id="ot-sdk-cookie-policy" />
        <button id="ot-sdk-btn" className="ot-sdk-show-settings bottom">
          Cookies Settings
        </button>
      </div>
    </>
  );
};

export default CookieSettings;
