import React, { useEffect } from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import tcConsumersPDF from "../../../../assets/docs/ActievoorwaardenConsumentHétLiptonTerrasvan2024.pdf";

const RulesPage = (props) => {
  const { setActiveMenuHeading, currentLocale } = props;
  useEffect(() => {
    setActiveMenuHeading("menu_rules");
  });
  return (
    <>
      <div className="rules-page-content">
        <h2>
          <FormattedMessage id="rules_vote_heading" />
        </h2>
        <p>
          <FormattedMessage id="rules_content_1" />
          <br />
          <br />
          <FormattedMessage id="rules_content_2" />
          <br />
          <br />
          <FormattedMessage id="rules_content_3" />
          <br />
        </p>
        <br />
        <p>
          <FormattedMessage id="rules_win_content" />
          {currentLocale === "nl-NL" && (
            <>
              <a href={tcConsumersPDF} target="_blank" rel="noreferrer">
                <FormattedMessage id="rules_win_content_href" />
              </a>
              <FormattedMessage id="rules_win_content_2" />
            </>
          )}
        </p>
        {currentLocale === "en-US" && <br />}
        {currentLocale === "en-US" && (
          <p>
            <FormattedMessage id="rules_content_5" />
          </p>
        )}
      </div>
    </>
  );
};

export default RulesPage;
